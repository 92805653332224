html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.landing-container {
    position: relative;
    float:right;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: 100%;
    background-image: url(images/SIF_data_platform.png), linear-gradient(to right, #242730 15%, #49657e 50%, #76b1dd 80%, #d1e5f4 100%);
    background-position: center right;
    background-size: 1075px;
    background-repeat: no-repeat;
    border-top-color: white;
    border-top-width: 0.3rem;
}


.header-text {
    margin-top: 7vh;
    color: #fff;
    font-size: 45px;
    line-height: 78px;
    text-align: center;
    position: absolute;
    left: 5%;
    right: 15%;
}


.text-container {
    position: absolute;
    top: 48%;
    left: 21%;
    transform: translate(-50%, -50%);

    min-width: 30rem;
    max-width: 55rem;
    width: 45%;
    color: white;
    background-color: #3A4552;
    border-color:  #242730;
    border-style: solid;
    border-width: 1px;
    border-radius: 2rem;
    padding: 0.7rem 3rem 2.3rem;
}

.text-container h1 {
    text-align: center;
}

.text-container a, p {
    text-decoration: none;
    text-align: justify;
    color: white;
    line-height: 1.55rem;
    padding-bottom: 5px;
}

.text-container div {
    margin-left: auto;
}

.button {
    color: #242730;
    font-weight: bold;
    height: 2.3rem;
    line-height: 2.3rem;
    margin:auto;
    width: 40%;
    border-radius: 2.3rem;
    background-color: white;
    text-align: center;
}

.button:hover {
    color: white;
    background-color: #3A4552;
    border: solid white 0.06rem;
}


/*.footer {*/
/*    background-image: url(images/logos.png); !* Replace with the path to your footer image *!*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*    background-position: center; !* Center the background image horizontally *!*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    height: 90px;*/
/*    left: 0;*/
/*    background-color: white;*/
/*    text-align: center;*/
/*    z-index: 1000;*/
/*}*/

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: white;
    text-align: center;
    z-index: 1000;
    padding-top: 10px;
    padding-bottom: 10px;

}

.footer img {
    margin: 0 15px; /* Adjust spacing between images */
    height: 70px; /* Adjust height of the images */
    vertical-align: middle; /* Align images vertically */
}

.add_padding_top_bottom {
    padding: 5px 0 5px 0;
}

.add_padding_left {
    padding-left: 5px;
}

