.select-panel {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, 4rem);
    justify-content: top;
    gap: 0.5rem;
    width: 27rem;
}

.select-panel p,
select {
    padding: 0.2rem;
    margin: 0.4rem;
}

.select-panel select {
    width: 15rem;
    height: 2.4rem;

    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;

    border-radius: 1.2rem;
}

.select-content {
    width: 100%;
    height: 100%;
}