.chart-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 0.5fr 1fr;
    height: 100%;
    border-top-width: 0.3rem;
    margin: 0rem auto;
    max-width: 120rem;
    padding: 2rem;
    grid-gap: 2rem;
}

.select-panel {
    grid-row: 1;
    grid-column: 1;
    margin: 0rem 0rem;
}

.dataSource-explanation {
    text-align: center;
    justify-self: left;
    width: 25rem;
    margin-top: 1rem;
    align-self: left;
    grid-column: 1;
    grid-row: 2;
}

.select-panel select {
    color: #242730;
    font-weight: bold;
    background-color: white;
}

.lineChart-wrapper {
    grid-column: 2 / span 2;
    grid-row: 2;
    border-radius: 1rem;
    overflow: hidden;
}

.pointMap-wrapper {
    grid-column: 2 / span 2;
    grid-row: 1;
    border-radius: 1rem;
    overflow: hidden;
}

.dataSource-explanation a:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

.dataSource-explanation a:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

.dataSource-explanation a:hover {
    color: white;
    background-color: transparent;
    text-decoration: underline;
}

.dataSource-explanation a:active {
    color: white;
    background-color: transparent;
    text-decoration: underline;
}