* {
    box-sizing: border-box;
}

.app {
    width: 100vw;
    height: 100vh
}

.header {
    height: 2.5rem
}

.content{
    height: calc(100% - 2.5rem);
    background-color: #242730;
    border-top-color: white;
}