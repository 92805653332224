.header-container {
    height: 100%;
    width: 100%;
    background-color: #242730;
}

.header-button-list {
    height: 100%;
    object-position: center left;
    list-style-type: none;
    padding-left: 10px;
    margin: 0px;
    overflow: hidden;
    background-color: #242730;
}

.header-element {
    object-position: center left;
    height: 100%;
    display: inline-block;
    margin: 0px
}

.header-element:hover {
    background-color: #3A4552
}

.header-link {
    padding: 8px;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: white;
    display: inline-block;
}