.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure it appears on top of other content */
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #49535e;
    padding: 20px;
    border-radius: 25px;
}


.loading-text {
  height: 100%;
  width: 100%;
  padding: 8px;
  color: white;
  display: inline-block;
}


.bar {
  width: 10px;
  height: 70px;
  background: #fff;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
  animation: loader 1.5s linear infinite;
}
.bar1 {
  animation-delay: 0.1s;
}
.bar2 {
  animation-delay: 0.2s;
}
.bar3 {
  animation-delay: 0.3s;
}
.bar4 {
  animation-delay: 0.4s;
}
.bar5 {
  animation-delay: 0.5s;
}
.bar6 {
  animation-delay: 0.6s;
}
.bar7 {
  animation-delay: 0.7s;
}
.bar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  50% {
    transform: scaleY(1);
    background: aqua;
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}